import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../layout';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';

import { GatsbyImage } from 'gatsby-plugin-image';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import RequestEstimateForm from '../components/requestEstimateForm';
import Obfuscate from 'react-obfuscate';

// if (typeof window !== `undefined`) {
//   require("bootstrap/dist/css/bootstrap.min.css")
// }

function Home(props) {
  return (
    <Layout>
      <div className="landing-container">
        <div className="posts-container">
          <Helmet title={config.siteTitle} />
          <SEO />
          {/* <!DOCTYPE html> */}

          <div className="site">
            <div style={{ maxWidth: '500px' }}></div>
            <div className="container site-content">
              <div className="row">
                <div className="col-sm-6">
                  <CarouselProvider
                    naturalSlideWidth={500}
                    naturalSlideHeight={500}
                    totalSlides={5}
                    isPlaying
                  >
                    <Slider>
                      <Slide index={0}>
                        <GatsbyImage
                          image={
                            props.data.stoneGolem.childImageSharp
                              .gatsbyImageData
                          }
                        />
                      </Slide>
                      <Slide index={1}>
                        <GatsbyImage
                          image={
                            props.data.diorBottleTop.childImageSharp
                              .gatsbyImageData
                          }
                        />
                      </Slide>
                      <Slide index={2}>
                        <GatsbyImage
                          image={
                            props.data.dreamforceWelcomeSign.childImageSharp
                              .gatsbyImageData
                          }
                        />
                      </Slide>
                      <Slide index={3}>
                        <GatsbyImage
                          image={
                            props.data.giantHead.childImageSharp.gatsbyImageData
                          }
                        />
                      </Slide>
                      <Slide index={4}>
                        <GatsbyImage
                          image={
                            props.data.twoFigures.childImageSharp
                              .gatsbyImageData
                          }
                        />
                      </Slide>
                    </Slider>
                    {/* <ButtonBack>Back</ButtonBack>
                      <ButtonNext>Next</ButtonNext> */}
                  </CarouselProvider>
                </div>

                <div className="col-sm-6 px-4 tw-pt-4 sm:pt-0">
                  <h1 className="text-cps-red">We build custom props.</h1>

                  <p>
                    We utilize a network of the best prop makers in the movie,
                    commercial, trade show, event, advertising, marketing and
                    themed entertainment industries to create amazing props.
                  </p>

                  <p>
                    We select the best-suited veteran artisans and fabricators
                    for your project — artisans who fabricate for some of the
                    most popular TV shows, the biggest movies and some of the
                    world’s best known companies, like Disney, Universal
                    Studios, NASA, Red Bull, Mazda, JetBlue, Dior and many more.
                  </p>

                  <p>
                    Our specialties include:
                    <ul className="mt-1 specialties-links">
                      <li>
                        <a href="#advertising-and-marketing">
                          Marketing Prop Makers & Builders
                        </a>
                      </li>
                      <li>
                        <a href="#events">Custom Event Props</a>
                      </li>
                      <li>
                        <a href="#trade-shows">
                          Large Custom Props for Trade Shows
                        </a>
                      </li>
                      <li>
                        <a href="#public-art">
                          Public Art & Lobby Sculpture Fabrication
                        </a>
                      </li>
                    </ul>
                  </p>

                  <p>
                    <b>
                      We're great at what we do and we're here to do it for you.
                    </b>
                  </p>

                  <div className="text-center">
                    <a
                      className=" btn btn-primary"
                      href="request-estimate.html"
                      style={{ marginTop: '15px' }}
                    >
                      Request an Estimate
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid testimonial bg-gray-300">
              <div className="spacer"></div>

              <div className="container my-5">
                <p>
                  "Absolutely blown away by your team's work and
                  professionalism. Client thrilled. Great execution. Great
                  teamwork."
                </p>
                <i>- Project Managers, Derse</i>

                <p>
                  [Derse is ranked as a Top 10 Experiential/Event Marketing
                  Agency by Advertising Age]
                </p>
              </div>

              <div className="spacer"></div>
            </div>

            <div className="container my-5" id="advertising-and-marketing">
              <div className="row">
                <div className="col-sm-6">
                  <GatsbyImage
                    image={
                      props.data.diorBottle.childImageSharp.gatsbyImageData
                    }
                  />
                </div>

                <div className="col-sm-6 my-3 px-4">
                  <h2 className="text-cps-red text-4xl pb-1">
                    For advertising and marketing.
                  </h2>

                  <p>
                    Because in our high tech world today where CGI is used so
                    often, the use of high-quality, attention-grabbing props are
                    even more effective than ever in getting the consumer to
                    take notice.
                  </p>

                  <p>
                    <b>
                      Real, 3D props, that consumers can see, touch and feel,
                      stand out like never before. They get noticed.
                    </b>
                  </p>

                  <p>
                    We work for some of the top brand management, marketing and
                    advertising agencies. We understand budgets are always tight
                    and deadlines are never far away. We get it. Just{' '}
                    <a href="request-estimate.html">send us your idea</a> and
                    give us as much detail as you can and we can usually develop
                    an estimate of cost within 24 hours. From there, depending
                    on your time crunch, we can usually meet any deadline.
                  </p>
                </div>
              </div>
            </div>

            <div className="container-fluid testimonial bg-gray-300">
              <div className="spacer"></div>

              <div className="container my-5">
                <p>
                  "Custom Prop Shop is a very unique place that can make
                  anything you can imagine. We loved their ability to pay close
                  attention to detail, their customer friendly attitude, and of
                  course their quick service."
                </p>
                <i>- Tampa Bay Rays</i>
              </div>

              <div className="spacer"></div>
            </div>

            <div className="container my-5" id="events">
              <div className="row">
                <div className="col-sm-6">
                  <GatsbyImage
                    image={
                      props.data.stoneGolem.childImageSharp.gatsbyImageData
                    }
                  />
                </div>

                <div className="col-sm-6 my-3 px-4">
                  <h2 className="text-cps-red text-4xl pb-1">For events.</h2>

                  <p>
                    The best way to make an event truly unique is by having
                    incredible props.
                  </p>

                  <p>
                    <b>
                      We work with you to create the exact high-quality pieces
                      you need to pull off an amazing event.
                    </b>
                  </p>

                  <p>
                    Whether you need a five foot wide replica of a camera, a six
                    foot tall vitamin bottle or anything else you have in mind,
                    we are always happy to give you a{' '}
                    <a href="request-estimate.html">free estimate</a> and
                    usually within 24-48 hours.
                  </p>

                  <p>
                    Just contact us with as much information as you have and
                    we'll take it from there.
                  </p>
                </div>
              </div>
            </div>

            <div className="container-fluid testimonial bg-gray-300">
              <div className="spacer"></div>

              <div className="container my-5">
                <p>
                  "The [prop] worked out great! We used it this past week at a
                  large conference and it went over well. We were happy with how
                  it turned out."
                </p>
                <i>- Broadcom</i>
              </div>

              <div className="spacer"></div>
            </div>

            <div className="container my-5" id="trade-shows">
              <div className="row">
                <div className="col-sm-6">
                  <GatsbyImage
                    image={
                      props.data.giantHeadFront.childImageSharp.gatsbyImageData
                    }
                  />
                </div>

                <div className="col-sm-6 my-3 px-4">
                  <h2 className="text-cps-red text-4xl pb-1">
                    For trade shows.
                  </h2>

                  <p>
                    It's not easy to stand out at a trade show, but everything
                    changes when you have an incredible prop as part of your
                    booth.
                  </p>

                  <p>
                    We've been building big, bold and eye-catching trade show
                    props for years which help our clients stand out and get
                    noticed.
                  </p>

                  <p>
                    Whether it's made out of foam, steel, plastic or fiberglass,
                    something unique and impressive gets peoples attention,
                    captures press, gets traffic.
                  </p>

                  <p>
                    <b>
                      Our prop makers are the best in the business at creating
                      pieces that make you look good.
                    </b>
                  </p>

                  <p>
                    Please call or{' '}
                    <Obfuscate email="info@custompropshop.com">email</Obfuscate>{' '}
                    with any questions if you'd like a free estimate on your
                    next project.
                  </p>
                </div>
              </div>
            </div>

            <div className="contianer-fluid testimonial bg-gray-300">
              <div className="spacer"></div>

              <div className="container my-5">
                <p>
                  "The statue looks amazing! The team did a great job on it. I
                  don't have their e-mail addresses but please pass this along
                  to the team. As always, if you ever need me as a reference
                  please don't hesitate to ask."
                </p>
                <i>- Area 23 - an Interpublic Group company</i>
              </div>

              <div className="spacer"></div>
            </div>

            <div className="container my-5" id="public-art">
              <div className="row">
                <div className="col-sm-6">
                  <GatsbyImage
                    image={props.data.moon.childImageSharp.gatsbyImageData}
                  />
                </div>

                <div className="col-sm-6 my-3 px-4">
                  <h2 className="text-cps-red text-4xl pb-1">
                    For public art.
                  </h2>

                  <p>
                    Companies and individuals are looking for ways to create
                    beauty and aesthetics in their working and living
                    environments.
                  </p>

                  <p>
                    They're looking for ways to give back to their communities
                    and maybe even bring them a bit of extra goodwill.
                  </p>

                  <p>
                    We utilize the top prop makers, sculptors and metal workers
                    in the business to make incredible, lasting pieces of art
                    that get attention and look great.
                  </p>

                  <p>
                    Let us know your idea for a public art piece and we'll help
                    you bring it to life.
                  </p>
                </div>
              </div>
            </div>

            <div className="container-fluid testimonial bg-gray-300">
              <div className="spacer"></div>

              <div className="container my-5">
                <p>
                  "I expect we will get some press out of this [prop]... More to
                  come. We have a huge winner!"
                </p>
                <i>- Brian C., Real Estate Development Company</i>
              </div>

              <div className="spacer"></div>
            </div>

            <div className="container my-5">
              <div className="max-w-6xl m-auto">
                <h2 className="text-cps-red text-4xl pb-1">
                  Need an estimate?
                </h2>

                <p>
                  Call us at <Obfuscate tel="(818) 738-7319" />
                </p>

                <p>
                  Or email us at <Obfuscate email="info@custompropshop.com" />
                </p>

                <p>Or fill out the form below:</p>

                <RequestEstimateForm />
              </div>
            </div>

            <div className="spacer"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;

export const squareImageCenter = graphql`
  fragment squareImageCenter on File {
    childImageSharp {
      gatsbyImageData(
        width: 800
        height: 800
        transformOptions: { cropFocus: CENTER }
        placeholder: BLURRED
      )
    }
  }
`;

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      gatsbyImageData(width: 800, height: 800, placeholder: BLURRED)
    }
  }
`;

//GatsbyImageSharpFluid_withWebp_noBase64
//GatsbyImageSharpFluid_withWebp_tracedSVG

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query HomeQuery {
    stoneGolem: file(relativePath: { eq: "stone-golem.jpg" }) {
      ...squareImageCenter
    }
    diorBottle: file(relativePath: { eq: "dior-bottle.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: BLURRED)
      }
    }
    giantHeadFront: file(relativePath: { eq: "giant-head-front.jpg" }) {
      ...squareImage
    }
    moon: file(relativePath: { eq: "moon.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: BLURRED)
      }
    }
    diorBottleTop: file(relativePath: { eq: "dior-bottle.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          height: 800
          quality: 100
          transformOptions: { cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
    dreamforceWelcomeSign: file(
      relativePath: { eq: "dreamforce-welcome-sign.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          height: 800
          quality: 100
          placeholder: BLURRED
        )
      }
    }
    giantHead: file(relativePath: { eq: "giant-head.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          height: 800
          quality: 100
          placeholder: BLURRED
        )
      }
    }
    twoFigures: file(relativePath: { eq: "two-figures.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          height: 800
          quality: 100
          placeholder: BLURRED
        )
      }
    }
  }
`;
